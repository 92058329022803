export const text = {
  login: 'ログイン',
  logout: 'ログアウト',
  scheduleMatch: '試合スケジュール',
  date: '日付',
  type: '種別',
  battleCard: '対戦カード',
  matchDetail: '試合詳細',
  home: 'home',
  away: 'away',
  newest: '最新ニュース',
  comparisonTableOfTeam1andTeam2: 'チーム1とチーム2の比較表',
  playerRank: '選手ランキング（デイリーランクバトル）',
  playerRankSS: ' 選手ランキング（シーズンロングバトル）',
  selectPart: 'シーズン選択',
  csvDownload: 'CSVダウンロード',
  note: '※ 太字：',
  pickNote: 'BEST-5, BEST-10の項目',
  selectTime: '表示期間選択',
  team: 'チーム',
  selectPosition: 'ポジション選択',
  name: '名前',
  position: 'ポジション',
  fault: '故障',
  retentionRate: '保有率%',
  price: '価格',
  optimizer: 'オプティマイザー',
  selectTour: '大会選択',
  selectPeriod: '表示期間選択',
  selectTeam: 'チーム選択',
  optimizeExecution: '最適化実行',
  optimizeResult: '最適化結果リスト',
  playerDisplay: '選手表示',
  abandonCategory: '放棄カテゴリ',
  showOwner: 'オーナーを表示',
  teamRank: 'チームランキング',
  teamStats: 'チームスタッツ',
  rank: 'ランク',
  value: '値',
  playerTeamStats: 'チーム別選手スタッツ',
  teamName: 'チーム名',
  end: '終了',
  start: '開始',
  displayTime: '表示期間',
  deptChart: 'Depth Charts',
  westArea: '西地区',
  centerArea: '中地区',
  eastArea: '東地区',
  possession: '所持',
  otherServiceLogin: '他のサービスIDでログイン',
  loginByEmail: 'メールアドレスでログイン',
  email: 'メールアドレス',
  password: 'パスワード',
  googleLogin: 'Googleでログイン',
  twitterLogin: 'Twitterでログイン',
  signup: 'ユーザー登録',
  otherServiceSignup: '他のサービスIDで新規登録',
  twitterSignup: 'Twitterで新規登録',
  googleSignup: 'Googleで新規登録',
  newSingup: 'メールアドレスで新規登録',
  register: '新規登録',
  signupNote1: '※本サービスのご利用に関して、利用規約およびプライバシーポリシーを必ずご確認ください。',
  signupNote2: 'ユーザー登録の実施は、利用規約およびプライバシーポリシーに同意したものとします。',

  changeEmail: 'メールアドレス変更',
  currentMail: '現在のメールアドレス',
  newMail: '新しいメールアドレス',
  change: '変更',
  changePassword: 'パスワード変更',
  currentPass: '現在のパスワード',
  newPass: '新しいパスワード',
  member: 'メンバーシップ',
  status: 'ステータス',
  agreement: '契約',
  leagueId: 'リーグID',
  afiliatedLeagues: '連携リーグ一覧',
  leagueName: 'リーグ名',
  myTeam: '自チーム',
  delete: '解除',
  refresh: '更新',
  resetPassword: 'パスワード再設定',
  setting: '設定',
  lock: 'Lock',
  exclude: 'Exclude',
  add: 'Add',
  owner: 'オーナー',
  leagueCoor: 'リーグ連携',
  remove: 'Remove',
  all: '合計',
  signupButton: '登録',
  contracted: '契約済み',
  uncontracted: '未契約',
  retypePass: 'パスワード再入力',
  displayStartDate: '表示開始日',
  displayEndDate: '表示終了日',
  letEveryoneParticipate: '全員出場させる',
  selectComparisonTeam1: '比較チーム1を選択',
  selectComparisonTeam2: '比較チーム2を選択',
  comparisonExecution: '比較実行',
  reflection: '反映',
  listOfPlayersAcquired: '獲得可能選手リスト',
  displayResult: '表示件数',
  simulator: 'シミュレーター',
  selectTeam1: 'チーム1を選択',
  selectTeam2: 'チーム2を選択',
  listFaObtained: '獲得可能FA選手リスト',
  simulationExecution: 'シミュレーション実行',
  simulationTeamStats: 'シミュレーションチームスタッツ',
  teamRankingStats: 'チームランキングスタッツ',
  dateSelection: '日付選択',
  leagueDaily: 'リーグ',
  matchSelection: '試合選択',
  teamNameDetail: '所属チーム',
  height: '身長',
  weight: '体重',
  playerDetail: '選手詳細',
  playerInformation: '選手情報',
  playerStats: '選手スタッツ',
  matchSpecificPlayerStats: '試合別選手スタッツ',
  season: 'シーズン',
  matchDay: '試合日',
  belongs: '所属',
  partner: '相手',
  score: 'スコア',
  teamDetail: 'チーム詳細',
  teamBasicInformation: 'チーム基本情報',
  district: '地区',
  homeArena: 'ホームアリーナ',
  grades: '成績',
  sectionIsFantasyLeague: '※節は、ファンタジーリーグにおけるシーズンロングの節です。',
  ownerName: 'オーナー名',
  thisSS: '今シーズン（合計）',
  guesThisSS: '今シーズン予測（合計）',
  last1Week: '直近1週間（合計） ',
  last2Week: '直近2週間（合計)',
  last1Month: '直近1か月（合計）',
  prevSS: '昨シーズン（合計）',
  avgThisSS: '今シーズン (平均) ',
  avgLast1Week: '直近1週間 (平均)',
  avglast2Week: '直近2週間 (平均)',
  avgLast1Month: '直近1か月 (平均) ',
  avgPrevSS: '昨シーズン (平均)',
  avgGuessSS: '今シーズン予測 (平均) ',
  allPlayer: '全選手',
  collectPlayer: '獲得可能選手',
  collectOwnPlayer: '獲得可能選手 + 所持選手',
  allTeam: '全てのチーム',
  dfsTool: 'Daily TOOL',
  ssTool: 'SeasonLong TOOL',
  memberContract: 'メンバーシップ契約',
  contractIntro:
    '・様々な分析でリーグ内に差を付けよう\n\n・Daily Fantasyでも深掘り分析！\n\n・B.LEAGUEそのものが更に楽しくなる分析機能が満載',
  introNote: '※メンバーシップ契約とは有料会員契約のことを指します。',
  contract: '契約する',
  introTool: 'SeasonLong Fantasy専用ツール',
  PRDes: `様々な角度で選手を一覧化！\nポジション別、特定スタッツ別、期間別、\n合計/平均別など\nどんな分析観点にもカスタマイズ自在！`,
  introSimulator: '選手獲得シミュレーター',
  SDes: 'シーズン中のFA獲得から、\nリーグ内におけるトレードまで\nあらゆる獲得シナリオをシミュレーションした上で\nWin-winになる獲得シナリオを導き出そう！',
  H2H: 'H2H Daily',
  H2HDes:
    '日にちごとに出場選手や選手放出/獲得をシミュレート！\n「今週は対戦相手に何勝何敗で勝てそうか？」\n「どのスタッツ項目を強化すれば勝ち星を増やせるか？」\nなど、具体的にリーグを勝ち抜くための戦略を練ろう！',
  TeamRank: 'チームランキング',
  TRDes:
    'リーグ内のチーム力を徹底比較！\n常勝軍団から波に乗っているチームまで、\nどの選手が主力になっているかを比較して\n作戦に活かそう！',
  DailySummary: 'Daily Summary',
  DSDes:
    '日にちごとに活躍選手をランキング化！\n自分の選手は上位に食い込めたか？\n注目すべきFA選手は誰か？\n毎日欠かさず目を通そう！',
  DepthChart: 'Depth Charts',
  DCDes:
    'B1,B2全球団の選手をチームごとに一覧化！\n各チームの出場時間配分は？\nプレイタイムさえ貰えれば活躍しそうな選手は誰か？\n次のブレイク選手を探して競争に差を付けよう！',
  BoxScore: 'Box Score（無料）',
  BSDes: 'どのサイトよりも見易いボックススコア！\nメンバーシップ契約をすると\n保有選手とFA選手も一目瞭然！',
  TeamPage: 'チームページ（無料）',
  TPDes: '各チームの基本情報から、\nスケジュール、所属選手の成績までを\nどこよりも見易く！',
  PlayerPage: '選手ページ（無料）',
  PPDes: '選手ごとの情報ページも搭載！\n各選手を様々な角度から分析しよう！',
  PlayerRankDFS: '選手ランキング（DFS）',
  PRDFSDes:
    'デイリーランクバトル専用の選手ランキング！\n選手価格や平均獲得ファンタジーポイントに加え、\n各スタッツ項目も並列で一覧化！\n日々の大会で上位に食い込むための戦略に活かそう！',
  Optimizer: '​オプティマイザー',
  ODes: 'デイリーランクバトルの各大会における\n最適ラインアップを自動計算！\n自動計算後のカスタマイズも自由自在！\n勝ちに行くラインアップを幾つも作って\n各大会に複数ラインアップを送り込もう！',
  fantasyLeague: 'ファンタジーリーグ連携',
  FLDes:
    'B.LEAGUE#LIVEで遊んでいるリーグと完全連動！\n狙えるFAが誰か、どの選手がどのチームに所属しているかが一目瞭然！\n複数リーグ遊んでいても簡単に切替可能！',
  dfsIntro: 'Daily Fantasy専用ツール',
  planCompare: 'プランの比較',
  gameEnd: '試合終了',
  gameNotStart: '試合前',
  gameStarting: '試合中',
  contest: '大会',
  // new text
  cancelSub: 'キャンセル',
  validateMail: '正しいメールアドレスをご入力ください。',
  validatePass: 'パスワードは半角英数混在で8文字以上20文字以内。',
  errorRegis: '登録できません。もう一度お試しください。',
  errorLoginGoogle: 'ログインできません。もう一度お試しください。',
  signupSuccess: '本登録が完了しました。',
  checkEmail:
    '現在、仮登録の状態です。\nただいま、ご入力いただいたメールアドレス宛に、\nご本人様確認用メールをお送りいたしました。\nメール本文内のURLをクリックすると、本登録が完了します。',
  forgotPassword: 'パスワードを忘れた方はこちら',
  league: '連携',
  validateNewPass: '新しいパスワードは現在のパスワードと一致しないようにご入力ください。',
  cancel: 'キャンセル',
  ok: 'OK',
  confirm: '本当によろしいでしょうか。',
  confirmChangeMail: '本当にメールアドレスを変更してもよろしいでしょうか？',
  confirmChangePass: '本当にパスワードを変更してもよろしいでしょうか？',
  confirmCancelSub: '本当にメンバーシップをキャンセルしてもよろしいでしょうか？',
  success: '保存されました',
  buySuccess: 'お支払いが完了しました。',
  changeSuccess: '変更完了しました。',
  validateHolderName: 'カードの名義人をご入力ください。',
  validateCardNumber: '16桁のカード番号をご入力ください。',
  validateExpire: 'カードの有効期限をご入力ください。',
  validateCVCCode: '3桁のセキュリティコードをご入力ください。',
  accountName: 'アカウント名',
  cardNumber: 'カード番号',
  expireDate: '有効期限',
  cvcCode: 'CVCコード',
  resend: '再送信',
  verifySuccess: '本人確認が完了しました。',
  notLogin: 'こちらのツールを使用するにはログインする必要があります。',
  notBuy: 'こちらのツールを使用するにはメンバーシップを契約する必要があります。',
  toLogin: 'ログイン画面へ',
  toProfile: 'ユーザー設定へ',
  validateField: 'この項目をご入力ください。',
  validateNewEmail: 'このメールアドレスは既に存在しています。',
  resetPassswordSuccess: 'パスワード再設定が完了しました。',
  cardRegis: 'クレジットカード情報',
  inputMail: 'メールアドレス入力',
  errorSimulator: '※エラーのため実行できません',
  linkedSuccess: 'リーグの連携が完了しました。',
  changeTeamSuccess: 'チームが変更されました。',
  removeLeague: '本当にこちらのリーグを解除してもよろしいでしょうか？',
  removeLeagueSuccess: 'リーグが解除されました。',
  nodata: 'データなし。',
  requireLeagueID: 'リーグIDを入力してください',
  fullPos: 'こちらのポジションが既に満員になりましたので、追加できません。',
  noTeam: 'チームがありません。',
  chooseTeam: '自チーム選択',
  cannotSub: 'メンバーシップはまだ有効期間中です。しばらくしてからもう一度お試しください。',
  seeMore: 'もっと見る',
  chooseTeam1: 'チーム1選択',
  chooseTeam2: 'チーム2選択',
  maximumPlayer: 'チーム内の選手は最大数に達しました。',
  invalidDate: '指定された日付が無効です。',
  overBudget: '※大会予算を超過しています',
  privatePage: 'No date',
  noteStatsG: '※G、GSは今季合計の値となります。',
  displayPeriodSelection: '表示期間選択',
  displayTimeDeepChart: '表示期間選択',
  beforeMatch: '試合前',
  subscriptionPlayerRanking: '選手ランキング（SLF）',
  subscriptionPlayerRankingDFS: '選手ランキング（DFS）',
  subscriptionDailySummaryDFS: 'Daily Summary（DFS）',
  subscriptionDailySummaryDFSDes:
    '日にちごとに活躍選手をランキング化！\n自分の選手は上位に食い込めたか？\n注目すべきFA選手は誰か？\n毎日欠かさず目を通そう！',
  subscriptionBoxScoreDFS: 'Box Score（DFS）',
  subscriptionBoxScoreDFSDes:
    'どのサイトよりも見易いボックススコア！\nメンバーシップ契約をすると\n保有選手とFA選手も一目瞭然！',
  subscriptionResultDFS: '過去大会の成績',
  subscriptionResultDFSDes:
    'BEST-5、10の過去大会の成績が見れる！\n大会でどのユーザーがどの選手を組んで\n大会上位を取ったかを参考にして次の大会に活かそう！',
  subscriptionContractFree1: '今季は無料！',
  subscriptionContractFree2: 'さっそく始める！',
  subscriptionDailySummary: 'Daily Summary（SLF）',
  subscriptionBoxScore: 'Box Score（SLF）',
  subscriptionTeamPage: 'チームページ',
  subscriptionPlayerPage: '選手ページ',
  subscriptionStart: '始める',
  invalidDateStart: '表示終了日より前の日付を選択してください。',
  invalidDateEnd: '表示開始日より後の日付を選択してください。',
  contractDescription: '※シーズンが終わるまでの契約になります',
  contractConfirmTitle: 'メンバーシップ契約注意事項',
  contractConfirmDescription1: '契約により解放された機能は2023年5月29日まで使用することができます。',
  contractConfirmDescription2: 'シーズン開始前に契約しても2023年5月29日まで使用することができます。',
  contractConfirmDescription3: '契約期間が終了すると再度契約が必要になります。自動での更新は行われません。',
  readMe: '必ずお読みください',
  redirectTerm: '特定商取引法及び資金決済法に基づく表記',
  ownershipRate: '保有率％',
  subscriptionFreeOneMonth: '初回1か月無料！さっそく始めよう！',
  startSubscription: 'さっそく始めよう！',
  membershipAgreementDialog:
    'メンバーシップ契約でTOOLが解放できます！\n契約することでより試合、\n選手を分析することができます！\n\n  詳細は下記から確認できます！',
  membershipAgreementDialog2:
    'メンバーシップ契約でTOOLが解放できます！\n契約することでより試合、\n選手を分析することができます！\n  詳細は下記から確認できます！',
  membershipFirstTimeDialog: 'メンバーシップを初契約の場合\n初回1ヶ月無料で使用できます！',
  buySubscriptionSuccessful: '購入成功',
  buySubscriptionFailed: '購入中断',
  buySubscriptionSuccessfulDescription: 'メンバーシップ契約が\n完了しました！\nさっそく使用してみましょう！',
  buySubscriptionFailedDescription: 'メンバーシップ契約が\n中断されました',
  tournamentType: '大会タイプ選択',
  tournament: '大会選択',
  username: 'ユーザー名',
  ranking: '順位',
  membershipTrialPeriodEnds: 'メンバーシップお試し期間終了',
  membershipTrialPeriodEndsDescription:
    'メンバーシップのお試し期間が終了しました。\n明日以降も今まで通り全ての機能を使用したい場合、メンバーシップの契約が必要になります。',
  becomeMemberAndAnalyzePlayer: 'メンバーになり、快適に選手たちの分析を行おう！',
  maskText: 'メンバーシップで\n解放',
  trialFreeSuccess: 'お試し契約完了しました',
  loginDescription:
    '​ログインすることで期間限定で有料機能が無料で使用できます！\nまずは新規登録を行い、全ての機能を使用してみましょう。​',
  japanesePlayer: '日本人枠',
  foreignPlayer: '外国人枠',
  specialPlayer: '帰化orアジア特別枠',
  nationalityFrame: '国籍枠',
  symbolSpecialPlayer: '帰/ア',
  symbolNationalityFrame: '外国​',
  japanese: '日本',
  naturalization: '帰化',
  asianSpecialFrame: 'アジア特別枠​',
  foreignNationality: '外国籍',
};
